// 刘志坤 2.3.0
<template>
  <div class="tree-style">
    <a-checkbox v-model:checked="checkAll" @change="onCheckAllChange" style="margin-top: 7px">全部部门</a-checkbox>
    <a-tree
      class="tree"
      checkable
      :tree-data="dataList"
      v-model:expandedKeys="expandedKeys"
      v-model:selectedKeys="selectedKeys"
      v-model:checkedKeys="checkedKeys"
      :checkStrictly="true"
      :replace-fields="replaceFields"
      @check="onCheck"
      @expand="onExpand"
    >
      <template #switcherIcon>
        <CaretDownOutlined />
      </template>
    </a-tree>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, watch,onMounted } from 'vue'
import { CaretDownOutlined } from '@ant-design/icons-vue'
export default defineComponent({
  components: { CaretDownOutlined },
  props: {
    dataList: {
      type: Array,
      default: []
    },
    checkData: {
      type: Array,
      default: []
    },
  },
  setup(props, ctx) {
    const { emit } = ctx
    const state = reactive({
      replaceFields: {
        children: 'departmentDTOList',
        title: 'departmentName',
        key: 'id'
      },
      selectedKeys: [],
      expandedKeys: [],
      treeData: [],
      checkedKeys: [],
      checkAll:false,
      dataScope: 0,
      list: [],
      departmentNames:[],
      allData:[]
    })
    const onCheck = (value, e) => {
      console.log('选中', value, e)
      let departmentNames=[];
      if(state.checkAll){
        state.checkAll=false;
        state.dataScope=0
      };
      console.log(state.allData,"总数据",value.checked);
      if(state.allData.length==value.checked.length){
        state.checkAll=true;
        state.dataScope=1;
        departmentNames.push("全部部门")
      }else{
         e.checkedNodes.forEach(val => {
          //  console.log(val.props.departmentName,'00000000000');
          departmentNames.push(val.props.departmentName)
        })
      }
      emit("selectedKeys",value.checked,departmentNames.join("、"),state.dataScope)
      // console.log(value.checked.indexOf('all'))
      // let departmentNames=[];
      // state.checkedKeys=[]
      // if (value.checked.indexOf('all') !=-1) {
      //   // console.log('全选')
      //   state.checkedKeys = dg(state.list)
      //   departmentNames.push('全部部门')
      //   state.dataScope = 1
      // } else {
      //   state.checkedKeys=value.checked
      // console.log(state.checkedKeys);
      // let departmentIds =dg(state.list).filter(val=>{
      //   return val!="all"
      // });
    }
    const onExpand = value => {
      console.log('展开',value)
    }

    // 全选的方法
    const onCheckAllChange=(e)=>{
      console.log(e,"多选");
      if(e.target.checked){
        state.checkedKeys = dg(state.list)
        state.departmentNames.push('全部部门')
        state.dataScope = 1
      }else{
        state.checkedKeys =[]
        state.departmentNames.push('')
        state.dataScope = 0
      }
      emit("selectedKeys",state.checkedKeys,state.departmentNames.join("、"),state.dataScope)
    }

    const dg = arr => {
      console.log(arr)
      let result = []
      if (arr.length == 0) return
      if (arr.length) fn(arr)
      function fn(arr) {
        arr.forEach(val => {
          result.push(val.id)
          if (val.departmentDTOList.length > 0) {
            fn(val.departmentDTOList)
          }
        })
      }
      return result
    }

    // 根据id删选文字
    const tg = (arr,idarr) => {
      console.log(arr)
      let array = []
      if (arr.length == 0) return
      if (arr.length) fn(arr,idarr)
      function fn(arr,idarr) {
        arr.forEach(val => {
          for(var i=0;i<=idarr.length;i++){
            if(val.id == idarr[i]){
              array.push(val.departmentName)
            }
          }
          if (val.departmentDTOList.length > 0) {
            fn(val.departmentDTOList,idarr)
          }
        })
      }
      return array
    }
    watch(
      () => props.checkData.length,
      () => {
        state.checkedKeys = props.checkData
        state.selectedKeys = props.checkData
        state.list = props.dataList;
        state.allData=dg(state.list);
        console.log(state.checkedKeys.length,state.allData.length);
        if(state.checkedKeys.length==state.allData.length){
          state.checkAll=true
        }
      },
      { immediate: true }
    )

    onMounted(()=>{
      const text = tg(props.dataList,props.checkData)
      state.checkedKeys = props.checkData
      state.selectedKeys = props.checkData
      state.list = props.dataList;
      state.allData=dg(state.list);
      console.log(state.checkedKeys,'选中',state.allData);
      state.checkedKeys.length==state.allData.length ? emit("selectedKeys",state.checkedKeys,"全部部门",state.dataScope) : emit("selectedKeys",state.checkedKeys,text.join("、"),state.dataScope)
    })
    return {
      ...toRefs(state),
      onCheck,
      onExpand,
      onCheckAllChange
    }
  }
})
</script>

<style lang="scss" scoped>
.tree-style .tree{
  max-height: 300px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: block;
    border-radius: 4px;
    background: #f5f5f5;
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    width: 8px;
    background: #dddddd;
    border-radius: 4px;
  }
}

// :deep(.ant-tree > li > .ant-tree-switcher) {
//   display: none !important;
// }
:deep(.ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected) {
  background-color: transparent;
}
</style>
